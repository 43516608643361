import React from 'react';

const Pagination = ({perPage, totalData, paginate}) => {
    const pageNumbers = [];

    const [page, setPage] = React.useState(1);

    for (let i = 1; i <= Math.ceil(totalData/perPage); i++) {
        pageNumbers.push(i);
    }
    return (
        <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
          <div className="flex-1 flex justify-between sm:hidden">
            <a
            href
            className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
            >
                Previous
            </a>
            <a href className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
                Next
            </a>
        </div>
        <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
            <div>
                <p className="text-sm text-gray-700">
                    Showing <span className="font-medium">{page}</span> to <span className="font-medium">{perPage}</span> of{' '}
                    <span className="font-medium">{totalData}</span> results
                </p>
            </div>
            <div>
            <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
            <a href className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
              <span className="sr-only">Previous</span>
            </a>
                {pageNumbers.map((number) => (
                    <a href="#" onClick={() => {paginate(number); setPage(number)}} key={number} className="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 hidden md:inline-flex relative items-center px-4 py-2 border text-sm font-medium">
                    {number}
                    </a>
                ))}
                <a href className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                <span className="sr-only">Next</span>
                </a>
            </nav>
          </div>
        </div>
    </div>
    )
}

export default Pagination;