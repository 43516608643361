import axios from "axios";

const token = localStorage.getItem("token");
export const baseAPI = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token ? token : null}`,
    withCredentials: true,
  },
});
export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL + '/api',
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token ? token : null}`,
    withCredentials: true,
  },
});

//auth token verify
export const TOKEN_VERIFY = "/scanner/user/verify";
export const CSRF_COOKIE = "/sanctum/csrf-cookie";

// AUTHENTICATION
export const LOGIN = "/scanner/login";
export const RESET_PASSWORD = "/scanner/password/reset";
export const FORGOT_PASSWORD = "/scanner/password/forgot";

// USER
// export const FETCH_USERS = '/api/fetch/all/users';
export const FETCH_USER = "/scanner/profile";
export const VERIFY_QR_CODES = "/scanner/ticket/scanner/verify";
export const GET_USER_EVENTS = "/scanner/user/event";

// ADMIN
export const ADD_USER = "/scanner/add/user";
export const ADD_ADMIN = "/scanner/add/admin";
export const GET_ADMINS = "/scanner/admins";
export const DELETE_USER = "/scanner/remove/user";
export const GET_SCANNER_USERS = "/scanner/scanner/user";
export const GET_EVENTS = "/scanner/events";
export const DISABLE_ENABLE_SCANNER_USER = "/scanner/disable/enable/scanner";
